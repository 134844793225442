<script>
  // your script goes here
  let loader = "/assets/images/loader2.gif";
</script>

<main class="min-w-full min-h-screen">
  <div class="w-full min-h-screen flex flex-col justify-center items-center">
    <img src={loader} alt="loader" class="w-[90vh]" />
    <h1 class="font-ABUSFagoPro text-2xl font-bold">Loading...</h1>
  </div>
</main>
