/* global google */

const createHTMLMapMarker = ({
  OverlayView = google.maps.OverlayView,
  ...args
}) => {
  class HTMLMapMarker extends OverlayView {
    constructor() {
      super();
      this.latlng = args.latlng;
      this.html = args.html;
      this.setMap(args.map);
    }

    createDiv() {
      this.div = document.createElement("div");
      this.div.style.position = "absolute";
      // this.div.style.background = "red"
      this.div.style.width = "40px";
      this.div.style.height = "40px";
      this.div.style.borderRadius = "50%";
      if (this.html) {
        this.div.innerHTML = this.html;
        // console.log(this.div)
      }
      google.maps.event.addListener(this.div, "click", (event) => {
        google.maps.event.trigger(this, "click");
      });
    }

    appendDivToOverlay() {
      const panes = this.getPanes();
      panes.overlayImage.appendChild(this.div);
    }

    positionDiv() {
      const point = this.getProjection().fromLatLngToDivPixel(this.latlng);
      let offset = 25;
      if (point) {
        this.div.style.left = `${point.x - offset}px`;
        this.div.style.top = `${point.y - offset}px`;
      }
    }

    draw() {
      if (!this.div) {
        this.createDiv();
        this.appendDivToOverlay();
      }
      this.positionDiv();
    }

    remove() {
      if (this.div) {
        this.div.parentNode.removeChild(this.div);
        this.div = null;
      }
    }

    getPosition() {
      return this.latlng;
    }

    getDraggable() {
      return false;
    }

    setPosition(loc) {
      if (!loc) {
        alert("No Location Found!");
        this.latlng = {
          lat: 0,
          lng: 0,
        };
        this.html = args.html;
      } else {
        this.latlng = loc;
      }
    }
  }

  return new HTMLMapMarker();
};

export default createHTMLMapMarker;
