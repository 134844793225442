<script>
  import { language } from "../resources/language";
  import { lang_mode } from "../store/index";
  export let textcolor;
  let termsOfService = "/assets/pdfs/Terms-of-Service.pdf";
  let privacyPolicy = "/assets/pdfs/Privacy-Policy.pdf";
</script>

<div
  class="pt-4 pb-6 lg:px-[140px] md:px-[95px] sm:px-[80px] xs:px-[60px] px-[30px]"
>
  <h2
    class="uppercase text-center xl:text-3xl lg:text-2xl md:text-xl text-lg font-ABUSFagoProBold {textcolor} lg:py-7 md:py-6 sm:py-5 xs:py-4 py-3"
  >
    {$lang_mode
      ? language[$lang_mode].disclaimer[1]
      : language["English"].disclaimer[1]}
  </h2>
  <p
    class="text-center xl:text-lg lg:text-base md:text-sm text-xs text-[#707070] font-ABUSFagoPro px-4"
  >
    {$lang_mode
      ? language[$lang_mode].disclaimer[2]
      : language["English"].disclaimer[2]}
    <br /><br />
    {$lang_mode
      ? language[$lang_mode].disclaimer[3]
      : language["English"].disclaimer[3]}
    <br /><br />
    {$lang_mode
      ? language[$lang_mode].disclaimer[4]
      : language["English"].disclaimer[4]}
    <br />
    {$lang_mode
      ? language[$lang_mode].disclaimer[5]
      : language["English"].disclaimer[5]}
    {$lang_mode
      ? language[$lang_mode].disclaimer[6]
      : language["English"].disclaimer[6]}
    <br /><br />
    {$lang_mode
      ? language[$lang_mode].disclaimer[7]
      : language["English"].disclaimer[7]}
    <span class="underline">
      <a href={termsOfService} target="_blank"
        >{$lang_mode
          ? language[$lang_mode].disclaimer["7.1"]
          : language["English"].disclaimer["7.1"]}</a
      >
    </span>
    {$lang_mode
      ? language[$lang_mode].disclaimer["7.2"]
      : language["English"].disclaimer["7.2"]}
    <span class="underline">
      <a href={privacyPolicy} target="_blank"
        >{$lang_mode
          ? language[$lang_mode].disclaimer["7.3"]
          : language["English"].disclaimer["7.3"]}</a
      >
    </span>
    {$lang_mode
      ? language[$lang_mode].disclaimer["7.4"]
      : language["English"].disclaimer["7.4"]}
  </p>
</div>
