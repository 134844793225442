import SosResponderMap from "../pages/SosRespondermap.svelte";
import CrashResponderMap from "../pages/CrashRespondermap.svelte";
import OnBoardingResponder from "../pages/onboardingResponder.svelte";
import AllowScreen from "../components/allowScreen.svelte";
import DownloadApp from "../components/downloadApp.svelte";
import Disclaimer from "../components/disclaimer.svelte";
import Copy from "../pages/copy.svelte";
import Loader from "../components/loader.svelte";
import Loader2 from "../components/loader2.svelte";
import Splash from "../components/splash.svelte";

export default [
  {
    path: "/",
    component: OnBoardingResponder,
  },
  {
    path: "/sos",
    component: SosResponderMap,
  },
  {
    path: "/crash",
    component: CrashResponderMap,
  },
  {
    path: "/allow",
    component: AllowScreen,
  },
  {
    path: "/copy",
    component: Copy,
  },
  {
    path: "/downloadapp",
    component: DownloadApp,
  },
  {
    path: "/disclaimer",
    component: Disclaimer,
  },
  {
    path: "/load",
    component: Loader,
  },
  {
    path: "/load2",
    component: Loader2,
  },
  {
    path: "/splash",
    component: Splash,
  },
];
