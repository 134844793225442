<script>
  import SimpleHeader from "../components/simpleHeader.svelte";
  import FooterBanner from "../components/footerBanner.svelte";
  import Splash from "../components/splash.svelte";
  import AllowScreen from "../components/allowScreen.svelte";

  import { useQuery } from "@sveltestack/svelte-query";
  import apicopy from "../resources/apicopy";
  import { query } from "../resources/queryparams";
  import { language } from "../resources/language";
  import { lang_mode } from "../store/index";

  let avatar = "/assets/images/avatar.svg";

  const apidata = useQuery("people", async () => {
    const res = await apicopy.get(
      `/users/buddy-request-details?${
        Object.keys(query).length === 1
          ? `hash=${query.hash}`
          : `rider_id=${query.rider_id}`
      }`
    );
    return res;
  });

  let value;
  $: {
    value = $apidata;
    // console.log($lang_mode);
    // console.log(value?.data?.data?.data?.user);
  }

  function allowButton() {
    apicopy
      .patch(
        `https://abus.quin.design/api/users/approve-buddy?hash=${query.hash}`,
        { hash: query.hash }
      )
      .then((res) => {
        document.getElementById("main").classList.add("hidden");
        document.getElementById("allow").classList.remove("hidden");
      })
      .catch((err) => {
        // console.log(err);
        if (
          err.response.data.status === false &&
          err.response.data.message === "This link has already been used."
        ) {
          document.getElementById("main").classList.add("hidden");
          document.getElementById("allow").classList.remove("hidden");
        }
      });
  }
</script>

{#if value.status === "loading" && value.data === undefined}
  <div class="w-full h-screen">
    <Splash />
  </div>
{:else}
  <section class="min-h-screen max-w-screen overflow-hidden">
    <SimpleHeader color="blue-gradient" />
    <div class="sm:min-h-full min-h-[75vh] ">
      <div id="main">
        <div
          class="flex flex-col items-center justify-center lg:pt-10 lg:pb-12 md:py-[72px] space-y-3 font-ABUSFagoPro xs:py-[85px] py-[105px]"
        >
          <img
            class="w-[90px] rounded-full"
            src={value?.data?.data?.data?.user?.avatar
              ? value?.data?.data?.data?.user?.avatar
              : avatar}
            alt="avatar"
          />
          <h1
            class="text-lg text-center xl:px-0 md:px-[90px] px-[45px] font-bold"
            id="eContact"
          >
            {value?.data?.data?.data?.user?.name
              ? value?.data?.data?.data?.user?.name
              : "Quin Designs"}
            {$lang_mode
              ? language[$lang_mode].beforeAllow[1]
              : language["English"].beforeAllow[1]}
          </h1>
          <p
            class="text-sm text-center font-ABUSFagoPro xl:px-0 md:px-[90px] px-[25px]"
            id="allowText"
          >
            {$lang_mode
              ? language[$lang_mode].beforeAllow[2]
              : language["English"].beforeAllow[2]}

            {value?.data?.data?.data?.user?.name
              ? value?.data?.data?.data?.user?.name
              : "Quin Designs"}. {$lang_mode
              ? language[$lang_mode].beforeAllow[3]
              : language["English"].beforeAllow[3]}
          </p>
          <button
            on:click={allowButton}
            id="allowBtn"
            class="text-sm blue-gradient px-8 py-1 rounded-full text-white font-semibold"
            >{$lang_mode
              ? language[$lang_mode].beforeAllow[4]
              : language["English"].beforeAllow[4]}</button
          >
        </div>
      </div>
      <div id="allow" class="hidden">
        <AllowScreen
          img={value?.data?.data?.data?.user?.avatar
            ? value?.data?.data?.data?.user?.avatar
            : avatar}
          name={value?.data?.data?.data?.user?.name}
        />
      </div>
    </div>
    <FooterBanner />
  </section>
{/if}
