<script>
  import { QueryClient, QueryClientProvider } from "@sveltestack/svelte-query";

  import router from "page";
  import routes from "./resources/routes";

  let page;
  let params;

  const queryClient = new QueryClient();

  routes.forEach((route) => {
    router(
      route.path,
      (ctx, next) => {
        params = ctx.params;
        next();
      },
      () => {
        page = route.component;
      }
    );
  });

  router.start();

  // console.log(process.env.GOOGLE_MAPS_API_KEY);
</script>

<QueryClientProvider client={queryClient}>
  <svelte:component this={page} {params} />
</QueryClientProvider>
