<script>
  import { language } from "../resources/language";
  import { lang_mode } from "../store/index";

  let onelink = "/assets/images/onelink.svg";
</script>

<div>
  <div
    class="flex flex-col justify-center items-center w-full space-y-4 font-ABUSFagoPro py-6 md:px-0 px-4 text-black"
  >
    <h1
      class="md:text-3xl sm:text-2xl xs:text-xl 2xs:text-base text-sm font-black"
    >
      {$lang_mode
        ? language[$lang_mode].download[1]
        : language["English"].download[1]}
    </h1>
    <p class="font-bold sm:text-sm xs:text-xs 2xs:text-2xs text-3xs">
      {$lang_mode
        ? language[$lang_mode].download[2]
        : language["English"].download[2]}
    </p>
    <img src={onelink} alt="onelink" />
    <div>
      <ul
        class="space-y-4 font-medium sm:text-sm xs:text-xs 2xs:text-2xs text-3xs"
      >
        <li class="list-disc">
          {$lang_mode
            ? language[$lang_mode].download[3]
            : language["English"].download[3]}
        </li>
        <li class="list-disc">
          {$lang_mode
            ? language[$lang_mode].download[4]
            : language["English"].download[4]}
        </li>
        <li class="list-disc">
          {$lang_mode
            ? language[$lang_mode].download[5]
            : language["English"].download[5]}
        </li>
      </ul>
    </div>
  </div>
</div>
