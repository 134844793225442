<script>
  // your script goes here
  let loader = "/assets/images/loader.gif";
</script>

<main
  class="bg-[url('/assets/images/loader-bg.png')] bg-no-repeat bg-cover bg-center max-h-screen min-w-full"
>
  <div class="w-full min-h-screen flex justify-center items-center">
    <img src={loader} alt="loader" class="w-64" />
  </div>
</main>
