<script>
  let quinSquare = "/assets/images/quin-logo-square.svg";
  let bell = "/assets/images/bell-icon.svg";
  let shield = "/assets/images/shield-icon.svg";
  let nav = "/assets/images/nav-icon.svg";
  let avatar = "/assets/images/avatar.svg";

  import { language } from "../resources/language";
  import { lang_mode } from "../store/index";
  import DownloadApp from "../components/downloadApp.svelte";

  export let img;
  export let name;

  let clickStatus = false;

  function download() {
    clickStatus = !clickStatus;
    if (clickStatus) {
      document.querySelector("main").classList.replace("flex", "hidden");
      document.querySelector("#download").classList.replace("hidden", "block");
    } else {
      document.querySelector("main").classList.replace("hidden", "flex");
      document.querySelector("#download").classList.replace("block", "hidden");
    }
  }
</script>

<div>
  <main
    class="flex flex-col items-center font-ABUSFagoPro space-y-4 py-8 md:px-0 px-12"
  >
    <div class="flex flex-col items-center space-y-2">
      <div>
        <img
          class="w-[90px] rounded-full"
          src={img ? img : avatar}
          alt="logo"
        />
      </div>
      <h1 class="font-ABUSFagoProBold text-center">
        {$lang_mode === "German"
          ? language.German.afterAllow[1]
          : $lang_mode !== "German"
          ? language[$lang_mode].afterAllow[1]
          : language["English"].afterAllow[1]}
        {name}
        {$lang_mode === "German" ? language.German.afterAllow["1.5"] : ""}
      </h1>
    </div>
    <div class="flex flex-col items-center space-y-6">
      <div class="flex flex-col items-center space-y-3">
        <img src={quinSquare} alt="Quinlogo" class="w-12 h-12" />
        <h1 class="font-ABUSFagoPro font-semibold">
          {$lang_mode
            ? language[$lang_mode].afterAllow[2]
            : language["English"].afterAllow[2]}
        </h1>
      </div>
      <div class="space-y-6 flex flex-col">
        <div class="flex space-x-4 font-ABUSFagoPro">
          <img
            src={bell}
            alt="bell"
            class="blue-gradient rounded-full h-6 w-6 p-1"
          />
          <h1>
            {$lang_mode
              ? language[$lang_mode].afterAllow[3]
              : language["English"].afterAllow[3]}
          </h1>
        </div>
        <div class="flex space-x-4 font-ABUSFagoPro">
          <img
            src={nav}
            alt="nav"
            class="blue-gradient rounded-full h-6 w-6 p-1"
          />
          <h1>
            {$lang_mode
              ? language[$lang_mode].afterAllow[4]
              : language["English"].afterAllow[4]}
          </h1>
        </div>
        <div class="flex space-x-4 font-ABUSFagoPro">
          <img
            src={shield}
            alt="shield"
            class="blue-gradient rounded-full h-6 w-6 p-1"
          />
          <h1>
            {$lang_mode
              ? language[$lang_mode].afterAllow[5]
              : language["English"].afterAllow[5]}
          </h1>
        </div>
      </div>
      <div>
        <button
          on:click={download}
          class="btn-primary font-ABUSFagoPro px-8 text-white blue-gradient"
          >{$lang_mode
            ? language[$lang_mode].afterAllow[6]
            : language["English"].afterAllow[6]}</button
        >
      </div>
      <div />
    </div>
  </main>
  <div class="hidden py-8 md:px-0 px-12" id="download">
    <DownloadApp />
  </div>
</div>
