<script>
  // your script goes here
  let loader = "/assets/images/loader.gif";
</script>

<main
  class="min-w-full min-h-screen bg-gradient-to-t from-[#02a9ff] to-[#0079f8]"
>
  <div class="w-full min-h-screen flex justify-center items-center">
    <img src={loader} alt="loader" class="w-64" />
  </div>
</main>
