<script>
  import { loader } from "../resources/gmaploader.js";
  import { onMount } from "svelte";
  import { mapStyles } from "../resources/mapstyles";
  import createHTMLMapMarker from "../resources/customMarker";

  let gmap, map, data, customMarker;
  const markerImg = "/assets/images/sosmarker.svg";

  export let location;
  export let marker;
  export let profile;

  data = {
    location: location,
  };

  onMount(() => {
    let responderLocation = {
      lat: 0,
      lng: 0,
    };
    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition((position) => {
        responderLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        // console.log(responderLocation);
      });
    } else {
      console.log("hello");
    }

    loader.load().then(() => {
      // console.log(data)
      let userLocation = {
        lat: parseFloat(data.location.lat),
        lng: parseFloat(data.location.lng),
      };
      // console.log(userLocation)
      const mapOptions = {
        center: userLocation,
        zoom: 16,
        streetViewControl: false,
        mapTypeControl: false,
        disableDefaultUI: true,
        styles: mapStyles[0],
        // zoomControl: true,
        // zoomControlOptions: {
        //   position: google.maps.ControlPosition.LEFT_TOP,
        // },
        // fullscreenControl: true,
      };
      // Map Init
      map = new google.maps.Map(gmap, mapOptions);
      // Map controls
      // map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(container);
      // Map markers
      customMarker = createHTMLMapMarker({
        latlng: userLocation,
        map: map,
        html: `<div style="position:relative;">
              <img style="width:160px;height:160px; position:absolute;left:50%;top:50%;transform:translate(-50%,-50%); z-index:-1;border-radius:50%;opacity:0.5;" src=${marker}>
              <img style="width:40px;height:40px;position:relative;left:0px; border-radius:50%;" src=${profile}>
              </div>`,
      });
      // customMarker.setPosition({lat:12.0979, lng:16.9986})
      // console.log(customMarker.getPosition());
      // new google.maps.Marker({
      //   position: userLocation,
      //   map: map,
      //   icon: {
      //     anchor: new google.maps.Point(80, 80),
      //     url: marker ? marker : markerImg,
      //     scaledSize: new google.maps.Size(160, 160),
      //   },
      //   optimized: false,
      // });
    });
  });
</script>

<div
  class="lg:mb-0 mb-1 bg-gray-400 lg:rounded-md lg:min-h-full min-h-[75vh]"
  bind:this={gmap}
/>
