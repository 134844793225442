<script>
  import { lang_mode } from "../store/index";
  let logo = "/assets/images/quinlogo.svg";
  export let color;
  let options = [{ lang: "English" }, { lang: "French" }, { lang: "German" }];
  let selected = "English";
  $: {
    $lang_mode = selected;
  }
</script>

<nav class="flex justify-between items-center md:px-10 px-4 {color}">
  <div class="flex md:space-x-3 md:py-7 py-5 space-x-1.5 items-center">
    <img src={logo} alt="quin-logo" class="md:w-[220px] w-[160px]" />
  </div>
  <div class="border-[2px] px-3 rounded-full flex cursor-pointer">
    <select
      class="outline-none py-1 pr-4 flex items-center bg-transparent text-white font-ABUSFagoPro"
      bind:value={selected}
    >
      {#each options as option}
        <option
          class="outline-none bg-transparent border-none text-black py-4"
          value={option.lang}
        >
          {option.lang}
        </option>
      {/each}
    </select>
  </div>
</nav>

<!-- <select
      class="outline-none py-1 pr-4 flex items-center bg-transparent text-white font-ABUSFagoPro"
      bind:value={selected}
    >
      {#each options as option}
        <option
          class="outline-none bg-transparent border-none text-black py-4"
          value={option.lang}
        >
          {option.lang}
        </option>
      {/each}
    </select>
    <button disabled>
      <img src="/assets/images/dropdown-icon.svg" alt="dropdown" class="" />
    </button> -->
