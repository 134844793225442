<script>
  import { useQuery } from "@sveltestack/svelte-query";

  import SimpleHeader from "../components/simpleHeader.svelte";
  import Maps from "../components/maps.svelte";
  import Disclaimer from "../components/disclaimer.svelte";
  import FooterBanner from "../components/footerBanner.svelte";
  import Profile from "../components/profile.svelte";
  // import Loading from "../components/loading.svelte";
  import Loader from "../components/loader.svelte";

  import { sos_store } from "../store/index";
  import apicopy from "../resources/apicopy";
  import { query } from "../resources/queryparams";

  const soscopy = useQuery("soscopy", async () => {
    const response1 = await apicopy.get(`/events/${query.event_id}`);
    return response1;
  });

  //   $: {
  //     console.log($soscopy);
  //   }

  let soscopyProp = {};
  let soscopyData;
  let loc;
  let marker = "/assets/images/Orange-Beacon.gif";

  $: {
    soscopyData = $soscopy;
    $sos_store = soscopyData;
    if (soscopyData.status === "success") {
      // console.log(soscopyData.data.data.data);
    }
    soscopyProp = {
      avatar: soscopyData?.data?.data?.data?.user?.avatar,
      blood_group: soscopyData?.data?.data?.data?.user?.blood_group,
      country_code: soscopyData?.data?.data?.data?.user?.country_code,
      helmet: soscopyData?.data?.data?.data?.helmet,
      impact_index: soscopyData?.data?.data?.data?.impact_index
        ? soscopyData?.data?.data?.data?.impact_index
        : 0,
      inserted_at: soscopyData?.data?.data?.data?.inserted_at,
      locations: soscopyData?.data?.data?.data?.locations,
      mobile: soscopyData?.data?.data?.data?.mobile,
      mobile_number: soscopyData?.data?.data?.data?.user?.mobile,
      name: soscopyData?.data?.data?.data?.user?.name,
      type: soscopyData?.data?.data?.data?.type,
    };

    loc = {
      lat: soscopyData?.data?.data?.data?.locations[0].lat,
      lng: soscopyData?.data?.data?.data?.locations[0].lng,
    };

    // console.log(soscopyProp);
  }
</script>

{#if $soscopy.status === "loading"}
  <Loader />
{:else}
  <section class="">
    <div>
      <SimpleHeader color="orange-gradient" />
    </div>
    <div
      class="flex lg:p-4 lg:pb-4 lg:flex-row flex-col w-full 2xl:min-h-[65vh] xl:min-h-[70vh] overflow-hidden"
    >
      <div class="lg:min-w-[calc(100vw_-_500px)] md:min-h-auto">
        <Maps location={loc} {marker} profile={soscopyProp?.avatar} />
      </div>
      <div>
        <Profile profileProps={soscopyProp} />
      </div>
    </div>
    <div>
      <Disclaimer textcolor="text-[#fe6836]" />
    </div>
    <div>
      <FooterBanner />
    </div>
  </section>
{/if}

<!-- 

    // console.log(query);

  // import { io } from "socket.io-client";

   // const socket = io.connect("http://65.0.90.255:4004", {
  //   transports: ["websocket"],
  // });

  // socket.on("connect", () => {
  //   // console.log("socket connected!", socket);
  //   socket.on(query.event_id, (data) => {
  //     // console.log("recieving");
  //     console.log(data);
  //   });
  // });
 -->
