export const language = {
  English: {
    beforeAllow: {
      1: "wants to add you as an emergency contact.",
      2: "By clicking “Allow” you will be added as a Quin for ABUS emergency contact for",
      3: "You will receive their location details in the event of an emergency.",
      4: "ALLOW",
    },
    afterAllow: {
      1: "You’ve been successfully added as an emergency contact for",
      2: "Get the Quin for ABUS Ride app",
      3: "Real time alerts in the event of an emergency",
      4: "Easy navigation to the location of emergency",
      5: "Updates: Rider status and dispatch of Emergency Services",
      6: "GET APP",
    },
    profile: {
      1: "Blood Group",
      2: "CALL NOW",
      3: " and might requires your assistance. Tap on call now to connect with your friend.",
      4: "SOS Triggered on :",
      5: "Navigate",
      6: "Pressing the navigate button will direct you to the last updated location of the rider.",
      7: "Phone Details",
      8: "Phone Model",
      9: "Helmet Details",
      10: "Helmet Model",
      11: "Phone Battery",
      12: "Helmet Battery",
    },
    disclaimer: {
      1: "DISCLAIMER",
      2: "For proper functioning of Quin’s smart safety features, including but not limited to, SOS Beacon, Automatic Crash Detection and other emergency alerts, it is mandatory for the User to be running the latest version of the Quin Ride app, and have network and/or data connectivity, wireless access, or any other tele-communications services necessary for them to send alerts or interact with the mobile application.",
      3: "It is mandatory for you, the responder,  to have network and/or data connectivity, wireless access, or any other tele-communications services necessary to receive timely alerts and/or notifications of the User’s status.",
      4: "The User assumes sole responsibility for enabling and keeping active the location tracking features (e.g. GPS, etc.) of their mobile device when they are using the App, and in no event shall Quin be responsible for any damages resulting from the User’s failure to do so.",
      5: "Quin does not guarantee the authenticity and/or severity of the incident, and does not guarantee that the appropriate help has been provided to the User. The Responder will be responsible for any and all actions that they take in response to this alert.",
      6: "Always check your helmet for internal and external damage before usage.",
      7: "Please read the ",
      7.1: "Terms of Service",
      7.2: " and ",
      7.3: "Privacy Policy",
      7.4: " for a more detailed explanation.",
    },
    sos: {
      1: "SOS Beacon (Live)",
      2: "SOS Beacon",
    },
    crash: {
      1: "Crash Alert",
      2: "Crash Detected on :",
      3: "and might requires your assistance. Tap on call now to connect with your friend.",
    },
    download: {
      1: "Download the Quin for ABUS App for free",
      2: "Scan the QR code to download",
      3: "Open the camera app on your phone.",
      4: "Focus the camera on the QR code.",
      5: "Tap the notification that pops up to open the download link.",
    },
  },
  French: {
    beforeAllow: {
      1: " veut t'ajouter comme contact d'urgence",
      2: 'En cliquant sur "Autoriser", tu seras ajouté comme contact d\'urgence Quin for ABUS par',
      3: "Tu recevras les détails de localisation en cas d'urgence.",
      4: "AUTORISER",
    },
    afterAllow: {
      1: "Vous avez été ajouté avec succès comme contact d'urgence de ",
      2: "Télécharge l'application Quin for ABUS Ride",
      3: "Alertes en temps réel en cas d'urgence",
      4: "Navigation facile vers le lieu de l'urgence",
      5: "Mises à jour : statut du conducteur ",
      6: "TÉLÉCHARGE L'APPLICATIO",
    },
    profile: {
      1: "Groupe sanguin",
      2: "APPELER MAINTENANT",
      3: " et pourrait avoir besoin de ton aide. Clique sur Appeler maintenant pour te mettre en contact avec lui.",
      4: "SOS déclenché :",
      5: "Naviguer",
      6: "Si tu appuies sur le bouton de navigation, tu seras redirigé vers la dernière mise à jour du conducteur. ",
      7: "Détails du smartphone",
      8: "Modèle de smartphone",
      9: "Détails du casque",
      10: "Modèle de casque",
      11: "Batterie de smartphone",
      12: "Casque Batterie",
    },
    disclaimer: {
      1: "AVIS DE NON-RESPONSABILITÉ",
      2: "Pour le bon fonctionnement des fonctions de sécurité intelligentes de Quin, y compris la balise SOS, la détection automatique d'accident et d'autres  alertes d'urgence, il est impératif que l'utilisateur dispose de la dernière version  de l'application Quin For ABUS et qu'elle dispose d'une connexion réseau et/ou de données. connexion, d'un accès sans fil ou d'autres services de télécommunication.  qui permettent d'envoyer des alertes ou d'interagir avec l'application mobile.  application est nécessaire.",
      3: "Il est impératif que toi, le contact d'urgence, disposiez d'une connexion réseau et/ou connexion de données, d'un accès sans fil ou d'autres services de télécommunication disposant des moyens nécessaires pour recevoir en temps utile des alertes et/ou des des notifications sur le statut de l'utilisateur sont nécessaires.",
      4: "L'utilisateur assume seul la responsabilité de l'activation des activer les fonctions de suivi de localisation (par ex. GPS, etc.) de son appareil mobile. et de le maintenir actif lorsqu'il utilise l'application.responsable de tout dommage résultant de l'omission de l'Utilisateur de le faire.",
      5: "Quin ne garantit pas l'authenticité et/ou la gravité de l'incident. incident et ne garantit pas que l'utilisateur a reçu l'aide appropriée.  Le contact d'urgence est responsable de toutes les mesures qu'il prend en réponse à ce prendre les mesures qui s'imposent.",
      6: "Vérifie toujours que ton casque ne présente pas de dommages internes ou externes avant de l'utiliser.",
      7: "Veuillez lire les ",
      7.1: "conditions d'utilisation",
      7.2: " et la ",
      7.3: "politique de confidentialité ",
      7.4: "pour une explication plus précise.",
    },
    sos: {
      1: "Balise SOS (en direct)",
    },
    crash: {
      1: "Alarme de chute",
      2: "Chute détectée : ",
      3: "et pourrait avoir besoin de ton aide. Clique sur Appeler maintenant pour te mettre en contact avec lui.",
    },
    download: {
      1: "Télécharge gratuitement l'application Quin for ABUS.",
      2: "Scanne le code QR pour télécharger.",
      3: "Ouvre l'appareil photo sur ton smartphone.",
      4: "Focalisez l'appareil photo sur le code QR.",
      5: "Appuyez sur la notification qui s'affiche pour ouvrir le lien de téléchargement.",
    },
  },
  German: {
    beforeAllow: {
      1: " möchte dich als einen Notfallkontakt hinzufügen",
      2: 'Durch Klicken von "Erlauben" wirst du als Quin for ABUS Notfallkontakt von hinzugefügt',
      3: "Du wirst Standortdetails im Notfall erhalten",
      4: "ERLAUBEN",
    },
    afterAllow: {
      1: "Du wurdest erfolgreich als Notfallkontakt von",
      1.5: "hinzugefügt.",
      2: "Lade die Quin for ABUS Ride App",
      3: "Echtzeit-Alarme im Notfall",
      4: "Einfache Navigation zum Ort des Notfalls",
      5: "Updates: Fahrerstatus ",
      6: "LADE DIR DIE APP",
    },
    profile: {
      1: "Blutgruppe",
      2: "JETZT ANRUFEN",
      3: " ausgelöst und könnte deine Hilfe benötigen. Klicke auf jetzt anrufen, um dich in Verbindung zu setzen.",
      4: "SOS ausgelöst:",
      5: "Navigiere",
      6: "Wenn du den Navigationsbutton drückst, wirst du zum zuletzt aktualisierten Standort des Fahrers geführt.",
      7: "Smartphone Details",
      8: "Smartphone Modell",
      9: "Helm Details",
      10: "Helm Modell",
      11: "Smartphone Akku",
      12: "Helm Akku",
    },
    disclaimer: {
      1: "HAFTUNGSAUSSCHLUSS",
      2: "Für das ordnungsgemäße Funktionieren der intelligenten Sicherheitsfunktionen von Quin, einschließlich SOS Beacon, automatische Unfallerkennung und andere Notfallwarnungen, ist es zwingend erforderlich, dass der Nutzer die neueste Version der Quin For ABUS App verwendet und über eine Netzwerk- und/oder Daten- verbindung, einen drahtlosen Zugang oder andere Telekommunikationsdienste verfügt, die für das Senden von Warnungen oder die Interaktion mit der mobilen Anwendung erforderlich sind.",
      3: "Es ist zwingend erforderlich, dass du, der Notfallkontakt über eine Netz- und/oder Datenverbindung, einen drahtlosen Zugang oder andere Telekommunikationsdienste verfügst, die für den rechtzeitigen Empfang von Warnungen und/oder Benachrichtigungen über den Status des Nutzers erforderlich sind.",
      4: "Der Nutzer übernimmt die alleinige Verantwortung dafür, die Standortverfolgungsfunktionen (z. B. GPS usw.) seines Mobilgeräts zu aktivieren und aktiv zu halten, wenn er die App nutzt, und Quin ist in keinem Fall für Schädenverantwortlich, die sich aus dem Versäumnis des Nutzers, dies zu tun, ergeben.",
      5: "Quin übernimmt keine Garantie für die Echtheit und/oder den Schweregrad des Vorfalls und garantiert nicht, dass dem Nutzer die angemessene Hilfe zuteil wurde.  Der Notfallkontakt ist für alle Maßnahmen verantwortlich, die er als Reaktion auf diese Meldung ergreift.",
      6: "Überprüfe deinen Helm vor der Benutzung stets auf innere und äußere Schäden.",
      7: "Bitte lies die ",
      7.1: "Nutzungsbedingungen",
      7.2: " und die ",
      7.3: "Datenschutzrichtlinie",
      7.4: "  für eine genauere Erklärung.",
    },
    sos: {
      1: "SOS Beacon (Live)",
    },
    crash: {
      1: "Sturzalarm",
      2: "Sturz erkannt: ",
      3: "ausgelöst und könnte deine Hilfe benötigen. Klicke auf jetzt anrufen, um dich in Verbindung zu setzen.",
    },
    download: {
      1: "Lade dir die Quin for ABUS App kostenlos.",
      2: "Scanne den QR Code zum Download.",
      3: "Öffne die Kamera auf deinem Smartphone.",
      4: "Fokussiere die Kamera auf den QR Code.",
      5: "Tippe auf die eingeblendete Benachrichtigung, um den Download-Link zu öffnen.",
    },
  },
};
