<script>
  import { useQuery } from "@sveltestack/svelte-query";

  import SimpleHeader from "../components/simpleHeader.svelte";
  import Maps from "../components/maps.svelte";
  import Disclaimer from "../components/disclaimer.svelte";
  import FooterBanner from "../components/footerBanner.svelte";
  import Profile from "../components/profile.svelte";
  import Loading from "../components/loading.svelte";

  import { crash_store } from "../store/index";
  import apicopy from "../resources/apicopy";
  import { query } from "../resources/queryparams";

  const crashcopy = useQuery("crashcopy", async () => {
    const response1 = await apicopy.get(`/events/${query.event_id}`);
    return response1;
  });

  //   $: {
  //     console.log($crashcopy);
  //   }

  let crashcopyProp = {};
  let crashcopyData;

  $: {
    crashcopyData = $crashcopy;
    $crash_store = crashcopyData;
    if (crashcopyData.status === "success") {
      // console.log(crashcopyData.data.data.data);
    }
    crashcopyProp = {
      avatar: crashcopyData?.data?.data?.data?.user?.avatar,
      blood_group: crashcopyData?.data?.data?.data?.user?.blood_group,
      country_code: crashcopyData?.data?.data?.data?.user?.country_code,
      helmet: crashcopyData?.data?.data?.data?.helmet,
      impact_index: crashcopyData?.data?.data?.data?.impact_index
        ? crashcopyData?.data?.data?.data?.impact_index
        : 0,
      inserted_at: crashcopyData?.data?.data?.data?.inserted_at,
      locations: crashcopyData?.data?.data?.data?.locations,
      mobile: crashcopyData?.data?.data?.data?.mobile,
      mobile_number: crashcopyData?.data?.data?.data?.user?.mobile,
      name: crashcopyData?.data?.data?.data?.user?.name,
      type: crashcopyData?.data?.data?.data?.type,
    };

    console.log(crashcopyProp);
  }

</script>

{#if $crashcopy.status === "loading"}
  <Loading />
{:else}
  <section class="">
    <div>
      <SimpleHeader color="red-gradient" />
    </div>
    <div
      class="flex lg:p-4 lg:pb-4 lg:flex-row flex-col w-full 2xl:min-h-[65vh] xl:min-h-[70vh] overflow-hidden"
    >
      <div class="lg:min-w-[calc(100vw_-_500px)] md:min-h-auto">
        <Maps />
      </div>
      <div>
        <Profile profileProps={crashcopyProp} />
      </div>
    </div>
    <div>
      <Disclaimer />
    </div>
    <div>
      <FooterBanner />
    </div>
  </section>
{/if}

<!-- 

    // console.log(query);

  // import { io } from "socket.io-client";

   // const socket = io.connect("http://65.0.90.255:4004", {
  //   transports: ["websocket"],
  // });

  // socket.on("connect", () => {
  //   // console.log("socket connected!", socket);
  //   socket.on(query.event_id, (data) => {
  //     // console.log("recieving");
  //     console.log(data);
  //   });
  // });
 -->
