<script>
  let playlogo = "/assets/images/Google_Play_Store.svg";
  let appstorelogo = "/assets/images/App_Store.svg";
</script>

<div class="relative overflow-hidden my-0">
  <div
    class="w-full bg-[url('/assets/images/web-footer.png')] bg-center bg-contain bg-no-repeat font-ABUSFagoPro text-white overflow-hidden pb-[44.17%] before:absolute z-0"
  />
  <div
    class="absolute overflow-hidden z-2 xl:bottom-10 lg:bottom-6 md:bottom-4 sm:bottom-3 xs:bottom-2.5 bottom-2 lg:right-16 md:right-10 sm:right-8 xs:right-6 right-3"
  >
    <h1
      class="2xl:text-xl xl:text-lg lg:text-base md:text-sm xs:text-xs mxs:text-2xs text-3xs py-1 font-thin font-ABUSFagoProBold text-white"
    >
      Available on
    </h1>
    <div class="flex items-center md:space-x-6 space-x-3">
      <a
        href="https://play.google.com/store/apps/details?id=com.quintessential.abus&hl=en_IN&gl=US"
        target="_blank"
        ><img
          class="lg:w-[140px] md:w-[90px] sm:w-[80px] xs:w-[60px] w-[40px]"
          alt="Get it on Google Play"
          src={playlogo}
        /></a
      >
      <a
        href="https://apps.apple.com/kh/app/quin-for-abus/id1511580268"
        target="_blank"
        ><img
          class="lg:w-[140px] md:w-[90px] sm:w-[80px] xs:w-[60px] w-[40px]"
          alt="Get it on Google Play"
          src={appstorelogo}
        />
      </a>
    </div>
  </div>
</div>
