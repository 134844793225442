export const mapStyles = [
  [
    // {
    //   featureType: "administrative",
    //   stylers: [
    //     {
    //       visibility: "off",
    //     },
    //   ],
    // },
    {
      featureType: "poi.attraction",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.business",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    // {
    //   featureType: "poi.government",
    //   stylers: [
    //     {
    //       visibility: "off",
    //     },
    //   ],
    // },
    {
      featureType: "poi.park",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.place_of_worship",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.school",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.sports_complex",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    // {
    //   featureType: "road.local",
    //   stylers: [
    //     {
    //       visibility: "off",
    //     },
    //   ],
    // },
    // {
    //   featureType: "transit.station",
    //   stylers: [
    //     {
    //       visibility: "off",
    //     },
    //   ],
    // },
  ],
];
