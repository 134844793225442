<script>
  import {
    dateTimeConverion,
    bloodgroupValue,
    helmetBatteryStatus,
    crashType,
    roundoff,
  } from "../resources/handyfuncs";
  let avatar = "/assets/images/avatar.svg";
  let helmetimg = "/assets/images/image-not-found.svg";

  import { language } from "../resources/language";
  import { lang_mode } from "../store/index";

  export let profileProps;
  let red = "text-[#d40828]";
  let orange = "text-[#fe6836]";
  let call = "/assets/images/call_icon.svg";

  // let sos_eng, sos_ger, sos_fer, crash_eng, crash_ger, crash_fer;

  // $:{
  //   sos_eng = (crashType(profileProps?.type) === "SOS" && $lang_mode === "English")
  //   sos_ger = (crashType(profileProps?.type) === "SOS" && $lang_mode === "German")
  //   sos_fer = (crashType(profileProps?.type) === "SOS" && $lang_mode === "French")
  // }

  // console.log(dateTimeConverion(profileProps.inserted_at));
</script>

{#if crashType(profileProps?.type) === "SOS"}
  <main class="font-ABUSFagoPro">
    <div class="lg:pl-6 px-4 lg:pt-0 lg:min-w-[450px] pt-4">
      <div class="space-y-6">
        <div
          class="space-y-4 shadow-[0_1.5px_3px_0px_rgba(0,0,0,0.16)] p-6 rounded-lg"
        >
          <h1 class="text-xl font-bold {orange}">
            {$lang_mode
              ? language[$lang_mode].sos[1]
              : language["English"].sos[1]}
          </h1>
          <div class="flex items-center justify-between font-semibold">
            <div class="flex justify-center items-center space-x-4">
              <img
                class="w-[40px] h-[40px] rounded-full"
                src={profileProps?.avatar ? profileProps?.avatar : avatar}
                alt="Profile pic"
              />
              <div class="overflow-hidden">
                <h1 class="max-w-[40vw] truncate">{profileProps?.name}</h1>
                <h1 class={orange}>
                  {bloodgroupValue(profileProps?.blood_group)}
                  {$lang_mode
                    ? language[$lang_mode].profile[1]
                    : language["English"].profile[1]}
                </h1>
              </div>
            </div>
            <div
              class="px-6 py-1 text-sm text-white rounded-full orange-gradient"
            >
              <a
                href={`tel:${profileProps?.country_code}${profileProps?.mobile_number}`}
              >
                {#if $lang_mode === "English"}
                  {language["English"].profile[2]}
                {:else}
                  <img src={call} alt="" class="w-4 mx-5 my-1" />
                {/if}
              </a>
            </div>
          </div>
          <div>
            <p>
              <span class="font-semibold">{profileProps?.name}</span>
              {$lang_mode === "English"
                ? " has triggered an"
                : $lang_mode === "German"
                ? " hat einen"
                : " a déclenché une"}
              <span class="font-ABUSFagoPro {orange} font-semibold">
                {$lang_mode === "French"
                  ? "Balise SOS "
                  : language["English"].sos[2]}</span
              >
              {$lang_mode
                ? language[$lang_mode].profile[3]
                : language["English"].profie[3]}
            </p>
          </div>
          <div class="h-[1.5px] bg-[#00000033] w-auto mx-2" />
          <div>
            <div class="flex justify-between items-center">
              <div class="font-AbusFagoPro-Bold font-semibold">
                <p>
                  {$lang_mode
                    ? language[$lang_mode].profile[4]
                    : language["English"].profile[4]}
                </p>
                <p class="">
                  {dateTimeConverion(profileProps.inserted_at)}
                </p>
              </div>
              <div
                class="px-6 py-1 rounded-full text-sm text-white font-semibold orange-gradient"
              >
                <button class="uppercase">
                  <a
                    href={`https://www.google.com/maps/search/${
                      profileProps?.locations[profileProps?.impact_index]?.lat
                    },${
                      profileProps?.locations[profileProps?.impact_index]?.lng
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                    >{$lang_mode
                      ? language[$lang_mode].profile[5]
                      : language["English"].profile[5]}</a
                  >
                </button>
              </div>
            </div>
            <div class={`py-2 text-sm font-normal leading-4`}>
              {$lang_mode
                ? language[$lang_mode].profile[6]
                : language["English"].profile[6]}
            </div>
          </div>
        </div>
        <div class="shadow-[0_1.5px_3px_0px_rgba(0,0,0,0.16)] p-6 rounded-lg">
          <div class="space-y-2">
            <div class="flex justify-between items-center font-ABUSFagoPro ">
              <p class="text-[#00000080] font-bold">
                {$lang_mode
                  ? language[$lang_mode].profile[7]
                  : language["English"].profile[7]}
              </p>
              <div class="w-[60%] h-[1.5px] bg-[#00000033]" />
            </div>
            <div class="flex justify-between items-center font-ABUSFagoPro">
              <div>
                <p class="capitalize font-bold">{profileProps?.mobile?.name}</p>
                <p class="text-xs">
                  {$lang_mode
                    ? language[$lang_mode].profile[8]
                    : language["English"].profile[8]}
                </p>
              </div>
              <div>
                <p class="text-lg text-right font-bold {orange}">
                  {roundoff(profileProps?.mobile?.battery, 0)}%
                </p>
                <p class="text-xs">
                  {$lang_mode
                    ? language[$lang_mode].profile[11]
                    : language["English"].profile[11]}
                </p>
              </div>
            </div>
            <div class="flex justify-between items-center font-ABUSFagoPro">
              <p class="text-[#00000080] font-bold">
                {$lang_mode
                  ? language[$lang_mode].profile[9]
                  : language["English"].profile[9]}
              </p>
              <div class="w-[60%] h-[1.5px] bg-[#00000033]" />
            </div>
            <div class="flex justify-between items-center font-ABUSFagoPro ">
              <div class="flex items-center space-x-4">
                <div class="rounded-full">
                  <img
                    class="w-10"
                    src={profileProps?.helmet?.url
                      ? profileProps?.helmet?.url
                      : helmetimg}
                    alt="helmet"
                  />
                </div>
                <div>
                  <p class="capitalize font-bold">
                    {typeof profileProps?.helmet?.name === "undefined"
                      ? "NA"
                      : profileProps?.helmet?.name}
                  </p>
                  <p class="text-xs">
                    {$lang_mode
                      ? language[$lang_mode].profile[10]
                      : language["English"].profile[10]}
                  </p>
                </div>
              </div>
              <div>
                <p class="text-lg text-right uppercase font-bold {orange} ">
                  {helmetBatteryStatus(profileProps?.helmet?.battery)}
                </p>
                <p class="text-xs">
                  {$lang_mode
                    ? language[$lang_mode].profile[12]
                    : language["English"].profile[12]}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
{:else}
  <main class="font-ABUSFagoPro">
    <div class="lg:pl-6 px-4 lg:pt-0 lg:min-w-[450px] pt-4">
      <div class="space-y-6">
        <div
          class="space-y-4 shadow-[0_1.5px_3px_0px_rgba(0,0,0,0.16)] p-6 rounded-lg"
        >
          <h1 class={`text-xl font-bold text-[#d40828]`}>
            {$lang_mode
              ? language[$lang_mode].crash[1]
              : language["English"].crash[1]}
          </h1>
          <div class="flex items-center justify-between font-semibold">
            <div class="flex justify-center items-center space-x-4">
              <img
                class="w-[40px] h-[40px] rounded-full"
                src={profileProps?.avatar ? profileProps?.avatar : avatar}
                alt="Profile pic"
              />
              <div class="overflow-hidden">
                <h1 class="max-w-[40vw] truncate">{profileProps?.name}</h1>
                <h1 class={red}>
                  {bloodgroupValue(profileProps?.blood_group)}
                  {$lang_mode
                    ? language[$lang_mode].profile[1]
                    : language["English"].profile[1]}
                </h1>
              </div>
            </div>
            <div
              class={`px-6 py-1 text-sm text-white rounded-full red-gradient text-center`}
            >
              <a
                href={`tel:${profileProps?.country_code}${profileProps?.mobile_number}`}
              >
                {#if $lang_mode === "English"}
                  {language["English"].profile[2]}
                {:else}
                  <img src={call} alt="" class="w-4 mx-5 my-1" />
                {/if}
              </a>
            </div>
          </div>
          <div>
            <p>
              <span class="font-semibold">{profileProps?.name}</span>
              {$lang_mode === "English"
                ? " had a"
                : $lang_mode === "German"
                ? " hat einen"
                : " a déclenché une"}
              <span class="font-ABUSFagoPro {red} font-semibold">
                {$lang_mode
                  ? language[$lang_mode].crash[1]
                  : language["English"].crash[1]}</span
              >
              {$lang_mode
                ? language[$lang_mode].crash[3]
                : language["English"].crash[3]}
            </p>
          </div>
          <div class="h-[1.5px] bg-[#00000033] w-auto mx-2" />
          <div>
            <div class="flex justify-between items-center">
              <div class="font-AbusFagoPro-Bold font-semibold">
                <p>
                  {$lang_mode
                    ? language[$lang_mode].crash[2]
                    : language["English"].crash[2]}
                </p>
                <p class="">
                  {dateTimeConverion(profileProps.inserted_at)}
                </p>
              </div>
              <div
                class={`px-6 py-1 rounded-full text-sm text-white font-semibold red-gradient`}
              >
                <button class="uppercase">
                  <a
                    href={`https://www.google.com/maps/search/${
                      profileProps?.locations[profileProps?.impact_index]?.lat
                    },${
                      profileProps?.locations[profileProps?.impact_index]?.lng
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                    >{$lang_mode
                      ? language[$lang_mode].profile[5]
                      : language["English"].profile[5]}</a
                  >
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="shadow-[0_1.5px_3px_0px_rgba(0,0,0,0.16)] p-6 rounded-lg">
          <div class="space-y-2">
            <div class="flex justify-between items-center font-ABUSFagoPro ">
              <p class="text-[#00000080] font-bold">
                {$lang_mode
                  ? language[$lang_mode].profile[7]
                  : language["English"].profile[7]}
              </p>
              <div class="w-[60%] h-[1.5px] bg-[#00000033]" />
            </div>
            <div class="flex justify-between items-center font-ABUSFagoPro">
              <div>
                <p class="capitalize font-bold">{profileProps?.mobile?.name}</p>
                <p class="text-xs font-semibold">
                  {$lang_mode
                    ? language[$lang_mode].profile[8]
                    : language["English"].profile[8]}
                </p>
              </div>
              <div>
                <p class="text-lg text-right font-bold {red}">
                  {roundoff(profileProps?.mobile?.battery, 0)}%
                </p>
                <p class="text-xs font-semibold">
                  {$lang_mode
                    ? language[$lang_mode].profile[11]
                    : language["English"].profile[11]}
                </p>
              </div>
            </div>
            <div class="flex justify-between items-center font-ABUSFagoPro">
              <p class="text-[#00000080] font-bold">
                {$lang_mode
                  ? language[$lang_mode].profile[9]
                  : language["English"].profile[9]}
              </p>
              <div class="w-[60%] h-[1.5px] bg-[#00000033]" />
            </div>
            <div class="flex justify-between items-center font-ABUSFagoPro ">
              <div class="flex items-center space-x-4">
                <div class="rounded-full">
                  <img
                    class="w-10"
                    src={profileProps?.helmet?.url
                      ? profileProps?.helmet?.url
                      : helmetimg}
                    alt="helmet"
                  />
                </div>
                <div>
                  <p class="capitalize font-bold">
                    {typeof profileProps?.helmet?.name === "undefined"
                      ? "NA"
                      : profileProps?.helmet?.name}
                  </p>
                  <p class="text-xs font-semibold">
                    {$lang_mode
                      ? language[$lang_mode].profile[10]
                      : language["English"].profile[10]}
                  </p>
                </div>
              </div>
              <div>
                <p
                  class={`text-lg text-right font-bold ${
                    crashType(profileProps?.type) === "SOS"
                      ? `${orange}`
                      : `${red}`
                  }`}
                >
                  {helmetBatteryStatus(profileProps?.helmet?.battery)}
                </p>
                <p class="text-xs font-semibold">
                  {$lang_mode
                    ? language[$lang_mode].profile[12]
                    : language["English"].profile[12]}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
{/if}
